<template>
  <v-card flat class="rounded-lg pa-2">
    <v-card-title class="text-color-title body-1 font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-text>
			<!-- {{ givingVideo }} -->
			<div class="d-flex">
				<video
					width="320"
					height="240"
					controls
					v-if="givingVideo.url"
				>
					<source :src="givingVideo.url" type="video/mp4">
					Your browser does not support the video tag.
				</video>
				<div class="mt-10 pl-4">
					<div>
						<small class="font-weight-bold">
							Description:
						</small>
					</div>
					<p>{{ givingVideo.description }}</p>
				</div>
			</div>

    </v-card-text>
  </v-card>
</template>

<script>
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";

import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const givingVideosRepository = RepositoryFactory.get("giving-videos");

export default {
	data: () => ({
		title: "Default Giving Video",
		givingVideo: {}
	}),
	methods: {
		async getDefaultGivingVideo() {
			await givingVideosRepository.default()
				.then(response => {
					this.givingVideo = response.data.responseData;
				})
				.catch(error => {
					console.log({ error })
				})
		},
		reloadItemsEventBus() {
      GLOBAL_EVENT_BUS.$off("reloadItems_givingVideos");
      GLOBAL_EVENT_BUS.$on("reloadItems_givingVideos", () => {
				this.getDefaultGivingVideo();
      });
    },
		setupEventBus() {
			this.reloadItemsEventBus();
		},
	},
	mounted() {
		this.setupEventBus();
		this.getDefaultGivingVideo();
	}
}
</script>